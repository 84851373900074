import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CityComp from 'src/sections/@dashboard/paths/CityComp';
import Map from './Map';

const City = () => {
  const { token } = useSelector((state) => state.auth);

  const [cityLoading, setCityLoading] = useState(true);
  const [city, setCity] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}admin/get_cities`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCityLoading(false);
        setCity(res.data.data);
      })
      .catch((error) => {
        console.log(error);
        setCityLoading(false);
      });
  }, [token]);

  const [showMap, setShowMap] = useState(false);

  
  const [markerPosition, setMarkerPosition] = useState(null);

  // handle add city 
  
  const [openAdd, setOpenAdd] = useState(false);

  const [name, setName] = useState('');

  const [nameEn, setNameEn] = useState('');

  const [description, setDescription] = useState('');

  //handle update city
  const [openUpdate, setOpenUpdate] = useState(false)

  const [nameUpdate, setNameUpdate] = useState("")

  const [nameEnUpdate, setNameEnUpdate] = useState("")

  const [descriptionUpdate, setDescriptionUpdate] = useState("")

  const [selectedElement, setSelectedElement] = useState({});

  const [isRefresh, setIsRefresh] = useState(true)



  return !showMap ? (
    <>
      <CityComp
        city={city}
        setCity={setCity}
        loading={cityLoading}
        setShowMap={setShowMap}
        openAdd={openAdd}
        setOpenAdd={setOpenAdd}
        markerPosition={markerPosition}
        setMarkerPosition={setMarkerPosition}
        name={name}
        setName={setName}
        nameEn={nameEn}
        setNameEn={setNameEn}
        description={description}
        setDescription={setDescription}
        openUpdate={openUpdate}
        setOpenUpdate={setOpenUpdate}
        nameUpdate={nameUpdate}
        setNameUpdate={setNameUpdate}
        nameEnUpdate={nameEnUpdate}
        setNameEnUpdate={setNameEnUpdate}
        descriptionUpdate={descriptionUpdate}
        setDescriptionUpdate={setDescriptionUpdate}
        selectedElement={selectedElement}
        setSelectedElement={setSelectedElement}
        isRefresh={isRefresh}
      />
    </>
  ) : (
    <>
      <Map setShowMap={setShowMap} markerPosition={markerPosition} setMarkerPosition={setMarkerPosition} setIsRefresh={setIsRefresh} />
    </>
  );
};

export default City;
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { MuiTelInput } from 'mui-tel-input';

const UpdateClient = ({ element, open, handleClose, setData, city }) => {
  const { token } = useSelector((state) => state.auth);



  const fileInputRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState("")

  const handleChangePhone = (value, info) => {
    setPhoneNumber(value);
    setCode(info.countryCallingCode);
  };





  const formik = useFormik({
    initialValues: {
      name: '',
      location: '',
      city_id: '',
      status: '',
      wallet: '',
    },
    onSubmit: (values) => {
      setLoading(true);
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('country_code', phoneNumber.substring(0, 4));
      formData.append('phone', phoneNumber.substring(5));
      formData.append('location', values.location);
      formData.append('city_id', values.city_id);
      formData.append('status', values.status);
      formData.append('wallet', values.wallet);
      formData.append('image', selectedFile);

      axios
        .post(`${process.env.REACT_APP_API_URL}admin/update_client/${element.id}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setLoading(false);
          setSuccessMessage('Updated Successfuly');
          setData((prev) =>
            prev.map((admin) =>
              admin.id === element.id
                ? {
                    ...admin,
                    name: values.name,
                    email: values.email,
                    birth_date: values.birth_date,
                    city_id: values.city_id,
                  }
                : admin
            )
          );
          handleClose();
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setErrorMessage('خطأ , أعد المحاولة رجاء');
        });
    },
  });

  useEffect(() => {
    if (element) {
      formik.setValues({
        name: element.name || '',
        location: element.location || '',
        city_id: element.city_id || '',
        status: element.status || '',
        wallet: element.wallet || '',
      });
      setPhoneNumber(element.phone);
    }
  }, [element, formik.setValues]);


  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const [successMessage, setSuccessMessage] = useState('');

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="alert-dialog-title">{'Update Client'}</DialogTitle>
          <DialogContent>
            <Grid container spacing={3} sx={{ marginTop: '20px' }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="الإسم"
                  name="name"
                  required
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MuiTelInput
                  value={phoneNumber}
                  onChange={handleChangePhone}
                  onlyCountries={['SY', 'BE']}
                  required
                  label="رقم الهاتف"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="الموقع"
                  name="location"
                  required
                  value={formik.values.location}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="المدينة"
                  name="city_id"
                  select
                  required
                  value={formik.values.city_id}
                  onChange={formik.handleChange}
                >
                  {city.map((element, index) => (
                    <MenuItem key={index} value={element.id}>
                      {element.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="الحالة"
                  name="status"
                  required
                  value={formik.values.status}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="المحفظة"
                  name="wallet"
                  required
                  // value={formik.values.wallet}
                  // value={formik.values.wallet === 0 ? '0' : formik.values.wallet}
                  value={formik.values.wallet}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                <label htmlFor="file">
                  <Button variant="contained" onClick={() => fileInputRef.current.click()}>
                    صورة
                  </Button>
                </label>
                <input
                  id="file"
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <LoadingButton type="submit" loading={loading} autoFocus>
              Agree
            </LoadingButton>
          </DialogActions>
        </form>
        {errorMessage && (
          <Typography variant="h6" sx={{ color: 'red', textAlign: 'center', padding: '10px 20px' }}>
            {errorMessage}
          </Typography>
        )}
        {successMessage && (
          <Typography variant="h6" sx={{ color: 'green', textAlign: 'center', padding: '10px 20px' }}>
            {successMessage}
          </Typography>
        )}
      </Dialog>
    </>
  );
};

export default UpdateClient;

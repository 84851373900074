import { Card, Container, Stack, Table, TableBody, TableContainer, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SkeletonTable from 'src/components/SkeletonTabel';
import Scrollbar from 'src/components/scrollbar';
import TripDetailsTableRow from 'src/sections/@dashboard/tripDetails/TripDetailsTableRow';
import { UserListHead } from 'src/sections/@dashboard/user';

const TABLE_HEAD = [
  { id: 'client_name', label: 'Client Name', alignRight: false },
  { id: 'client_phone', label: 'Client Phone', alignRight: false },
  { id: 'seats_count', label: 'Seats Count', alignRight: false },
  { id: 'client_point', label: 'Client Point', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'evaluation', label: 'Evaluation', alignRight: false },
  { id: 'is_cancelled', label: 'Is Cancelled', alignRight: false },
];

const TripsDetails = () => {
  const { id } = useParams();
  const { token } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(true);

  const [trip, setTrip] = useState({});

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}admin/trip_details/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setTrip(res.data.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [token, id]);

  //   handle Table pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <Container>
      <Stack direction="row" sx={{ justifyContent: 'space-between', my: 4 }}>
        <Typography>Started at: {trip.started_at}</Typography>
        <Typography>Finished at: {trip.finished_at}</Typography>
      </Stack>
      <Stack direction="row" sx={{ justifyContent: 'space-between', my: 4 }}>
        <Typography>Seats count: {trip.seats_count}</Typography>
        <Typography>Status: {trip.status}</Typography>
      </Stack>
      <Stack direction="row" sx={{ justifyContent: 'space-between', my: 4 }}>
        <Typography>Evaluation: {trip.evaluation}</Typography>
        <Typography>price: {trip.price}</Typography>
      </Stack>
      <Stack direction="row" sx={{ justifyContent: 'space-between', my: 4 }}>
        <Typography>Company rate: {trip.company_rate}</Typography>
      </Stack>

      <Card>
        <Scrollbar>
          <TableContainer>
            <Table>
              <UserListHead headLabel={TABLE_HEAD} />
              <TableBody>
                {Object.keys(trip).length > 0 && !loading ? (
                  trip.orders
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((element, index) => <TripDetailsTableRow key={index} element={element} />)
                ) : (
                  <SkeletonTable number={4} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </Container>
  );
};

export default TripsDetails;

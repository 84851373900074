import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import { MuiTelInput } from 'mui-tel-input';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const status = [{ name: 'default' }, { name: 'waiting_passengers' }, { name: 'in_progress' }, { name: 'started' }];

const UpdateDriver = ({ open, setOpen, setData, handleCloseMenu, element }) => {
  const { token } = useSelector((state) => state.auth);
  const handleClose = () => {
    setOpen(false);
    setErrorMessage('');
    handleCloseMenu();
  };

  const [phoneNumber, setPhoneNumber] = useState('');

  const handleChangePhone = (value, info) => {
    setPhoneNumber(value);
  };

  const [values, setValues] = useState({
    name: '',
    location: '',
    current_city: '',
    status: '',
    wallet: '',
    path_id: '',
    phoneNumber: '',
  });

  useEffect(() => {
    if (element) {
      setValues({
        name: element.name || '',
        location: element.location || '',
        current_city: element.city_id || '',
        status: element.status || '',
        wallet: element.wallet || '',
        path_id: element.path_id || '',
        phoneNumber: element.phone || '',
      });
      setPhoneNumber(element.phone);
    }
  }, [element]);

  const handleChange = (e) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const fileInputRef = useRef(null);

  const [selectFile, setSelectFile] = useState(null);
  const [imageUrl, setImageUrl] = useState('');

  const handleOpenFile = () => {
    fileInputRef.current.click();
  };

  const handleSelectFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectFile(e.target.files[0]);
      setImageUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSendApi = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append('name', values.name);
    let countryCode = phoneNumber.split('-')[0];
    if (!countryCode.startsWith('+')) {
      countryCode = `+${countryCode}`;
    }
    formData.append('country_code', countryCode);
    formData.append('phone', phoneNumber.split('-')[1]);
    formData.append('location', values.location);
    formData.append('current_city', values.current_city);
    formData.append('status', values.status);
    formData.append('wallet', values.wallet);
    formData.append('path_id', values.path_id);
    formData.append('image', selectFile);

    axios
      .post(`${process.env.REACT_APP_API_URL}admin/update_driver/${element.id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        handleCloseMenu();
        setOpen(false);
        setData((prev) =>
          prev.map((driver) =>
            driver.id === element.id
              ? {
                  ...driver,
                  name: values.name,
                  phone: `${values.countryCode}${values.phoneNumber.split('-')[1]}`,
                  location: values.location,
                  current_city: values.current_city,
                  status: values.status,
                  wallet: values.wallet,
                  path_id: values.path_id,
                  image: imageUrl,
                }
              : driver
          )
        );
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage('خطأ , أعد المحاولة رجاء');
        }
        setLoading(false);
      });
  };

  const [city, setCity] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}admin/get_cities`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCity(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  const [path, setPath] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}admin/get_paths`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setPath(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={handleSendApi}>
          <DialogTitle id="alert-dialog-title">{'تحديث معلومات السائق'}</DialogTitle>
          <DialogContent>
            <Grid container spacing={3} sx={{ marginTop: '20px' }}>
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="Name" name="name" required value={values.name} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} md={6}>
                <MuiTelInput
                  value={phoneNumber}
                  onChange={handleChangePhone}
                  onlyCountries={['SY', 'BE']}
                  required
                  label="رقم الهاتف"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="الموقع"
                  name="location"
                  required
                  value={values.location}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="المدينة"
                  name="current_city"
                  select
                  required
                  value={values.current_city}
                  onChange={handleChange}
                >
                  {city.map((element, index) => (
                    <MenuItem key={index} value={element.id}>
                      {element.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="الحالة"
                  name="status"
                  required
                  select
                  value={values.status}
                  onChange={handleChange}
                >
                  {status.map((element, index) => (
                    <MenuItem key={index} value={element.name}>
                      {element.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="المحفظة"
                  name="wallet"
                  required
                  value={values.wallet}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="المسار"
                  name="path_id"
                  select
                  required
                  value={values.path_id}
                  onChange={handleChange}
                >
                  {path.map((element, index) => (
                    <MenuItem key={index} value={element.id}>
                      من {element.source_city} الى {element.destination_city}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                <label htmlFor="file">
                  <Button variant="contained" onClick={handleOpenFile}>
                    صورة السائق
                  </Button>
                </label>
                <input
                  id="file"
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleSelectFile}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <LoadingButton loading={loading} type="submit" autoFocus>
              Agree
            </LoadingButton>
          </DialogActions>
          {errorMessage && (
            <Typography variant="h6" sx={{ color: 'red', padding: '10px 20px', textAlign: 'center' }}>
              {errorMessage}
            </Typography>
          )}
        </form>
      </Dialog>
    </>
  );
};

export default UpdateDriver;

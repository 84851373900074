import { IconButton, TableCell, TableRow } from '@mui/material'
import React from 'react'
import Iconify from 'src/components/iconify'

const CityTablerow = ({element, handleOpenMenu}) => {
  return (
    <>
     <TableRow>
            <TableCell align='left'>{element.name}</TableCell>
            <TableCell align='left'>{element.name_en}</TableCell>
            <TableCell align='left'>{element.description}</TableCell>
            <TableCell align="right">
          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, element)}>
            <Iconify icon={'eva:more-vertical-fill'} />
          </IconButton>
        </TableCell>
        </TableRow>
    </>
  )
}

export default CityTablerow
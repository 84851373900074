import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const AddAdmin = ({ open, setOpen, setData }) => {
  const { token } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    setErrorMessage('');
    setSuccessMessage('');
  };

  // handle car image
  const fileInputRef = useRef(null);
  const [selectFile, setSelectFile] = useState(null);

  const handleOpenFile = () => {
    fileInputRef.current.click();
  };

  const handleSelectFile = (e) => {
    setSelectFile(e.target.files[0]);
  };


  const [phoneNumber, setPhoneNumber] = useState('');

  const [code, setCode] = useState('');

  const handleChangePhone = (value, info) => {
    setPhoneNumber(value);
    setCode(info.countryCallingCode);
  };




  const formik = useFormik({
    initialValues: {
      name: '',
      password: '',
    },
    onSubmit: (values) => {
      setLoading(true);
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('country_code', `+${code}`);
      formData.append('phone', phoneNumber.replace(`+${code}`, '').replace(/\s/g, ''));
      formData.append('password', values.password);
      formData.append('image', selectFile);

    setErrorMessage("")
    setSuccessMessage("")

      axios
        .post(`${process.env.REACT_APP_API_URL}admin/create_admin`, formData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setLoading(false);
          setSuccessMessage('Added Success');
          setOpen(false)

          setData((prev) => [...prev, res.data.admin]);
        })
        .catch((error) => {
          console.log(error);
          if(error.response){
            setErrorMessage(error.response.data.error);
          }else{

            setErrorMessage('Error, please try again');
          }
          setLoading(false);
        });
    },
  });



  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="alert-dialog-title">{'Add Employee'}</DialogTitle>
          <DialogContent>
            <Grid container spacing={3} sx={{ marginTop: '20px' }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  required
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MuiTelInput
                  value={phoneNumber}
                  onChange={handleChangePhone}
                  onlyCountries={['SY', 'BE']}
                  required
                  label="Phone"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Password"
                  name="password"
                  required
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                <label htmlFor="file">
                  <Button variant="contained" onClick={handleOpenFile}>
                    Image
                  </Button>
                </label>
                <input
                  id="file"
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleSelectFile}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <LoadingButton type="submit" loading={loading} autoFocus>
              Agree
            </LoadingButton>
          </DialogActions>
        </form>
        {errorMessage && (
          <Typography variant="h6" sx={{ color: 'red', textAlign: 'center', padding: '10px 20px' }}>
            {errorMessage}
          </Typography>
        )}
        {successMessage && (
          <Typography variant="h6" sx={{ color: 'green', textAlign: 'center', padding: '10px 20px' }}>
            {successMessage}
          </Typography>
        )}
      </Dialog>
    </>
  );
};

export default AddAdmin;

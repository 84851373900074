import {
  Button,
  Card,
  Container,
  MenuItem,
  Popover,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import SkeletonTable from 'src/components/SkeletonTabel';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { UserListHead } from 'src/sections/@dashboard/user';
import USERLIST from '../_mock/user';
import ClientTableRow from 'src/sections/client/ClientTableRow';
import CreateClient from 'src/sections/client/CreateClient';
import UpdateClient from 'src/sections/client/UpdateClient';

const TABLE_HEAD = [
  { id: 'name', label: 'الاسم', alignRight: false },
  { id: 'phone', label: 'الهاتف', alignRight: false },
  { id: 'location', label: 'الموقع', alignRight: false },
  { id: 'status', label: 'الحالة', alignRight: false },
  { id: 'wallet', label: 'المحفظة', alignRight: false },
  { id: 'city', label: 'المدينة', alignRight: false },
  { id: '' },
];


const Clients = () => {
  const { token } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);

  const [clients, setClients] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}admin/get_clients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setClients(res.data.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [token]);

  // handle table
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleOpenMenu = (event, element) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedClient(element);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // handle popover
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // handle update
  const [openUpdate, setOpenUpdate] = useState(false);

  const [selectedClient, setSelectedClient] = useState(null);

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
    setAnchorEl(null);
  };


  // handle create client
  const [openCreate, setOpenCreate] = useState(false);

  //get city to send it tp create and update client
  const [city, setCity] = useState([])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}admin/get_cities`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      setCity(res.data.data)
    })
    .catch(error => {
      console.log(error)
    })
  }, [token])



  return (
    <>
      <Helmet>
        <title> المستخدمين </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            العملاء
          </Typography>
          <Button variant="contained" onClick={() => setOpenCreate(true)}>
            عميل جديد
          </Button>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {loading ? (
                    <SkeletonTable number={5} />
                  ) : (
                    clients
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((element, index) => (
                        <ClientTableRow key={index} element={element} handleOpenMenu={handleOpenMenu} />
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => setOpenUpdate(true)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          تحديث المستخدم
        </MenuItem>
      </Popover>
      <CreateClient open={openCreate} setOpen={setOpenCreate} setData={setClients} city={city} />
      <UpdateClient
        open={openUpdate}
        handleClose={handleCloseUpdate}
        element={selectedClient}
        setData={setClients}
        city={city}
      />
    </>
  );
};

export default Clients;

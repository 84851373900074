import { TableCell, TableRow } from '@mui/material';
import React from 'react';

const DriverTripTable = ({ element, setOpenMap, setSelectedTrip }) => {

  const handleClick = () => {
    setOpenMap(true)
    setSelectedTrip(element)
  }

  return (
    <>
      <TableRow style={{ cursor: 'pointer' }} onClick={handleClick}>
        <TableCell align="left">{element.started_at}</TableCell>
        <TableCell align="left">{element.finished_at}</TableCell>
        <TableCell align="left">{element.seats_count}</TableCell>
        <TableCell align="left">{element.status}</TableCell>
        <TableCell align="left">{element.evaluation}</TableCell>
        <TableCell align="left">{element.company_rate}</TableCell>
        <TableCell align="left">{element.price}</TableCell>
      </TableRow>
    </>
  );
};

export default DriverTripTable;

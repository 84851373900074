import { Card, Container, MenuItem, Popover, Stack, Table, TableBody, TableContainer, TablePagination, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import SkeletonTable from 'src/components/SkeletonTabel';
import Scrollbar from 'src/components/scrollbar';
import { UserListHead } from 'src/sections/@dashboard/user';
import ClientDetailsTableRow from 'src/sections/client/ClientDetailsTableRow';
import USERLIST from '../_mock/user';
import Iconify from 'src/components/iconify';
import { CloseFullscreen } from '@mui/icons-material';
import DriverInfo from 'src/sections/client/DriverInfo';
import CarInfo from 'src/sections/client/CarInfo';


const TABLE_HEAD = [
    { id: 'source', label: 'Source', alignRight: false },
    { id: 'destination', label: 'Destination', alignRight: false },
    { id: 'price', label: 'Price', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false },
    { id: 'day', label: 'Day', alignRight: false },
    { id: 'seats_count', label: 'Seats Count', alignRight: false },
    { id: '' },
  ];

const ClientDetails = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const {id} = useParams()
    const {token} = useSelector(state => state.auth)

    const [loading, setLoading] = useState(false)

    const [userTrip, setUsertrip] = useState([])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}admin/get_client_trips/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(res => {
            setUsertrip(res.data.data)
        })
        .catch(error => {
            console.log(error)
        })
    }, [token, id])

    // handle table pagination 
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setPage(0);
      setRowsPerPage(parseInt(event.target.value, 10));
    };


    // handle popover 
    const [anchorEl, setAnchorEl] = useState(null);

    const handleCloseMenu = () => {
      setAnchorEl(null);
    };

    const [selectedCar, setSelectedCar] = useState({})

    const [selectedDriver, setSelectedDriver] = useState({})




    const handleOpenMenu = (event, element) => {
      setAnchorEl(event.currentTarget);
      setSelectedCar(element.car_info);
      setSelectedDriver(element.driver_info)
    };


    //handle driver and car dialog
    const [openCar, setOpenCar] = useState(false)

    const handleCloseCar = () => {
      setOpenCar(false)
      setAnchorEl(null)
    }

    const [openDriver, setOpenDriver] = useState(false)

    const handleCloseDriver = () => {
      setOpenDriver(false)
      setAnchorEl(null)
    }


  return (
    <>
      <Helmet>
        <title> User | Minimal UI </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Client: {searchParams.get("client")}
          </Typography>
        </Stack>
        <Card>
            <Scrollbar>
                <TableContainer>
                    <Table>
                    <UserListHead headLabel={TABLE_HEAD} />
                    <TableBody>
                        {
                            loading ? <SkeletonTable number={4} />
                            :
                            userTrip.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((element, index) => (

                                <ClientDetailsTableRow key={index} element={element} handleOpenMenu={handleOpenMenu} />
                            ))
                        }
                    </TableBody>
                    </Table>
                </TableContainer>
            </Scrollbar>
            <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => setOpenCar(true)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Car Info
        </MenuItem>
        <MenuItem onClick={() => setOpenDriver(true)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Driver Info
        </MenuItem>
      </Popover>
      <DriverInfo data={selectedDriver} open={openDriver} handleClose={handleCloseDriver} />
      <CarInfo data={selectedCar} open={openCar} handleClose={handleCloseCar} />
    </>
  );
};

export default ClientDetails;

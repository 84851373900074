import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { store } from './store';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chartt';
import ScrollToTop from './components/scroll-to-top';
import './styles.css';

// ----------------------------------------------------------------------

export default function App() {
  // const [notification, setNotification] = useState({});
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Provider store={store}>
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <Router />
          </ThemeProvider>
        </Provider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

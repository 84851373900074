import { Avatar, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Iconify from 'src/components/iconify';

const ClientTableRow = ({ element, handleOpenMenu }) => {
  const navigate = useNavigate()
  return (
    <>
      <TableRow sx={{ cursor: 'pointer' }} onClick={() => navigate(`/dashboard/client/trip/details/${element.id}?client=${element.name}`)}>
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={element.name} src={`${process.env.REACT_APP_API_URL_IMAGE}${element.image}`} />
            <Typography variant="subtitle2" noWrap>
              {element.name}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="left">{element.phone}</TableCell>
        <TableCell align="left">{element.location}</TableCell>
        <TableCell align="left">{element.status}</TableCell>
        <TableCell align="left">{element.wallet}</TableCell>
        <TableCell align="left">{element.city}</TableCell>
        <TableCell align="right">
          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, element)}>
            <Iconify icon={'eva:more-vertical-fill'} />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ClientTableRow;

import {
  Button,
  Card,
  Container,
  MenuItem,
  Popover,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Scrollbar from 'src/components/scrollbar';
import { UserListHead } from '../user';
import SkeletonTable from 'src/components/SkeletonTabel';
import PathsTableRow from './PathsTableRow';
import Iconify from 'src/components/iconify';
import AddPath from './AddPath';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import USERLIST from '../../../_mock/user';
import UpdatePath from 'src/sections/@dashboard/paths/UpdatePath';

const TABLE_HEAD = [
  { id: 'source_city', label: 'مدينة المغادرة', alignRight: false },
  { id: 'destination_city', label: 'مدينة الوصول', alignRight: false },
  { id: 'one_ticket_price', label: 'سعر التذكرة', alignRight: false },
  { id: 'access_time', label: 'وقت الوصول', alignRight: false },
  { id: '' },
];

const PathsComp = ({ city }) => {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [paths, setPaths] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}admin/get_paths`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setPaths(res.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [token]);

  // handle Table pagination
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // handle popover
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [selectedElement, setSelectedElement] = useState({});
  const handleOpenMenu = (e, element) => {
    e.stopPropagation();
    setSelectedElement(element);
    setAnchorEl(e.currentTarget);
  };

  const [openAdd, setOpenAdd] = useState(false);

  //handle update path
  const [openUpdate, setOpenUpdate] = useState(false);

  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            المسارات
          </Typography>
          <Button variant="contained" onClick={() => setOpenAdd(true)}>
            إضافة مسار
          </Button>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {loading ? (
                    <SkeletonTable number={3} />
                  ) : (
                    paths
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((element, index) => (
                        <PathsTableRow key={index} element={element} handleOpenMenu={handleOpenMenu} />
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => setOpenUpdate(true)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          تحديث المسار
        </MenuItem>
        <MenuItem onClick={() => navigate(`/dashboard/paths/drivers/${selectedElement.id}`)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          مسار السائق
        </MenuItem>
      </Popover>
      <AddPath open={openAdd} setOpen={setOpenAdd} setData={setPaths} city={city} />
      <UpdatePath
        open={openUpdate}
        setOpen={setOpenUpdate}
        setData={setPaths}
        setAnchorEl={setAnchorEl}
        element={selectedElement}
      />
    </>
  );
};

export default PathsComp;

import { IconButton, TableCell, TableRow } from '@mui/material';
import React from 'react';
import Iconify from 'src/components/iconify';

const ClientDetailsTableRow = ({ element, handleOpenMenu }) => {
  return (
    <>
      <TableRow>
        <TableCell align="left">{element.source_city_name}</TableCell>
        <TableCell align="left">{element.destination_city_name}</TableCell>
        <TableCell align="left">{element.price}</TableCell>
        <TableCell align="left">{element.date}</TableCell>
        <TableCell align="left">{element.day}</TableCell>
        <TableCell align="left">{element.seats_count}</TableCell>
        <TableCell align="right">
          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, element)}>
            <Iconify icon={'eva:more-vertical-fill'} />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ClientDetailsTableRow;

import { Avatar, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Iconify from 'src/components/iconify';
import { useEffect, useState } from 'react';
// import { generateToken, messaging } from './notifications/firebase';
// import { onMessage } from 'firebase/messaging';
// import { generateToken, messaging } from 'src/notifications/firebase';

const DriversTableRow = ({ element, handleOpenMenu, status }) => {
  const navigate = useNavigate();
  // const [payloadNotification, setPayloadNotification] = useState({});
  // useEffect(() => {}, [status]);
  //   generateToken();

  //   onMessage(messaging, (payload) => {
  //     // console.log(payload.data.key);
  //     if (payload.data.key === 'driver') {
  //       setPayloadNotification(JSON.parse(payload.data.driver));
  //       // console.log(payload.data.driver);
  //       console.log(JSON.parse(payload.data.driver));
  //     }
  //   });
  // }, [payloadNotification]);
  // console.log(payloadNotification?.id);
  return (
    <TableRow
      hover
      tabIndex={-1}
      role="checkbox"
      sx={{ cursor: 'pointer' }}
      onClick={() => navigate(`/dashboard/driver/details/${element.id}?name=${element.name}`)}
    >
      <TableCell component="th" scope="row" padding="none">
        {/* <div style={{ marginTop: '50px' }}>
          <br />
          {notification?.title}
          <br />
          {notification?.body}
        </div> */}
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar alt={element.name} src={`${process.env.REACT_APP_API_URL_IMAGE}${element.image}`} />
          <Typography variant="subtitle2" noWrap>
            {element.name}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell align="left">{element.phone}</TableCell>

      <TableCell align="left">{element.location}</TableCell>
      <TableCell align="left">{element.current_city}</TableCell>
      <TableCell align="left">
        {element.status}

        {}
      </TableCell>
      <TableCell align="left">{element.wallet}</TableCell>
      <TableCell align="left">
        {status === '' ? (element.is_online === 1 ? 'Yes' : 'No') : status === 'online' ? 'Yes' : 'No'}
        {/* {element.is_online === 1 ? 'Yes' : 'No'} */}
      </TableCell>
      <TableCell align="left">{element.started_online_at}</TableCell>
      <TableCell align="right">
        <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, element)}>
          <Iconify icon={'eva:more-vertical-fill'} />
        </IconButton>
      </TableCell>
      {/* )} */}
    </TableRow>
  );
};

export default DriversTableRow;

import { Card, Container, Stack, TableBody, TableContainer, Typography, Table } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import SkeletonTable from 'src/components/SkeletonTabel';
import Scrollbar from 'src/components/scrollbar';
import DriverCarTable from 'src/sections/@dashboard/drivers/DriverCarTable';
import DriverTripTable from 'src/sections/@dashboard/drivers/DriverTripTable';
import TripMap from 'src/sections/@dashboard/drivers/TripMap';
import { UserListHead } from 'src/sections/@dashboard/user';

const TABLE_HEAD_CAR = [
  { id: 'name', label: 'الاسم', alignRight: false },
  { id: 'number', label: 'الرقم', alignRight: false },
  { id: 'color', label: 'اللون', alignRight: false },
  { id: 'passengers_count', label: 'عدد الركاب', alignRight: false },
];

const TABLE_HEAD_TRIP = [
  { id: 'started_at', label: 'بدأ في', alignRight: false },
  { id: 'finished_at', label: 'انتهى في', alignRight: false },
  { id: 'seats_count', label: 'عدد المقاعد', alignRight: false },
  { id: 'status', label: 'الحالة', alignRight: false },
  { id: 'evaluation', label: 'التقييم', alignRight: false },
  { id: 'company_rate', label: 'تقييم الشركة', alignRight: false },
  { id: 'price', label: 'السعر', alignRight: false },
  { id: 'map', label: 'الخريطة', alignRight: false },
  { id: '' },
];

const DriverDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const { token } = useSelector((state) => state.auth);

  // handle car info

  const [loadingCar, setLoadingCar] = useState(false);
  const [driverCar, setDriverCar] = useState({});

  useEffect(() => {
    setLoadingCar(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}admin/get_driver_car/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoadingCar(false);
        setDriverCar(res.data.data);
      })
      .catch((error) => {
        setLoadingCar(false);
        console.log(error);
      });
  }, [id, token]);

  // handle trip info

  const [trip, setTrip] = useState([]);
  const [loadingTrip, setLoadingTrip] = useState(false);

  useEffect(() => {
    setLoadingTrip(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}admin/get_driver_trips/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoadingTrip(false)
        setTrip(res.data.data);
      })
      .catch((error) => {
        setLoadingTrip(false)
        console.log(error);
      });
  }, [token, id]);

  const [openMap, setOpenMap] = useState(false)

  const handleCloseMap = () => {
    setOpenMap(false)
  }

  const [selectedTrip, setSelectedTrip] = useState({})


  return (
    <>
      <Helmet>
        <title> المستخدم </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="center" mb={5}>
          <Typography variant="h4" gutterBottom>
            السائق: {searchParams.get('name')}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {searchParams.get('name')} السيارة
          </Typography>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer>
              <Table>
                <UserListHead headLabel={TABLE_HEAD_CAR} />
                <TableBody>
                  {loadingCar ? (
                    <SkeletonTable number={5} />
                  ) : Object.keys(driverCar).length !== 0 ? (
                    <DriverCarTable element={driverCar} />
                  ) : (
                    ''
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mt={8} mb={5}>
          <Typography variant="h4" gutterBottom>
            {searchParams.get('name')}: الرحلة
          </Typography>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer>
              <Table>
                <UserListHead headLabel={TABLE_HEAD_TRIP} />
                <TableBody>
                  {loadingTrip ? (
                    <SkeletonTable number={6} />
                  ) : (
                    trip.map((element, index) => <DriverTripTable key={index} element={element} setOpenMap={setOpenMap} setSelectedTrip={setSelectedTrip} />)
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
      <TripMap open={openMap} handleClose={handleCloseMap} element={selectedTrip} />
    </>
  );
};

export default DriverDetails;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import {
  Typography,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  CircularProgress,
  Paper,
  InputBase,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { login, changeError } from '../../../store/authSlice';
// components
import Iconify from '../../../components/iconify';
import PhoneInput from 'react-phone-input-2';
import { MuiTelInput } from 'mui-tel-input';
// import { clearConfigCache } from 'prettier';
// import {changeError} from "../../../store/authSlice"

// -----------------------------------------------------------------------

export default function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, loading } = useSelector((state) => state.auth);

  const [showPassword, setShowPassword] = useState(false);

  // const [values, setValeus] = useState({
  //   phone: '',
  //   password: '',
  //   fcm_token: "666"
  // });

  const [phoneNumber, setPhoneNumber] = useState('');

  const [code, setCode] = useState('');

  const [password, setPassword] = useState('');

  const handleChangePhone = (value, info) => {
    setPhoneNumber(value);
    setCode(info.countryCallingCode);
  };

  const handleClick = (e) => {
    e.preventDefault();

    const phoneNumberWithoutCountryCode = phoneNumber.replace(`+${code}`, '').replace(/\s/g, '');

    const info = {
      country_code: `+${code}`,
      phone: phoneNumberWithoutCountryCode,
      password: password,
      // fcm_token:
      //   'e1_ix2y_T0-gUIYB1Rf5-5:APA91bEmPykOlAt5GifPceWgbn5P3tHt_YOqzOcYWuWxlqTt52V78tjeUed4gZGRKWPqsW-4FRpewQuu1rSGgaQG1UCkLIaKFd074K7g10g9DCnRQXmlkt7-jrypdo-jy8Ef2eae29BA',
      fcm_token: localStorage.getItem('fcm_token'),
    };

    dispatch(login(info)).then((res) => {
      if (res.payload.message === 'successful login') {
        setPassword('');
        setCode('');
        setPhoneNumber('');
        navigate('/dashboard', { replace: true });
      }
    });
  };

  useEffect(() => {
    dispatch(changeError(''));
  }, [code, phoneNumber, password]);

  return (
    <>
      <form onSubmit={handleClick}>
        <Stack spacing={3}>
          <MuiTelInput
            value={phoneNumber}
            onChange={handleChangePhone}
            onlyCountries={['SY', 'BE']}
            required
            label="رقم الهاتف"
          />

          <TextField
            name="password"
            required
            label="كلمة المرور"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ my: 2 }}>
          {loading ? <CircularProgress sx={{ color: '#fff' }} /> : 'تسجيل الدخول'}
        </LoadingButton>
        {error && (
          <Typography variant="h6" sx={{ color: 'red', padding: '10px 20px', textAlign: 'center' }}>
            {error}
          </Typography>
        )}
      </form>
    </>
  );
}

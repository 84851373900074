import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useState } from 'react';

// ----------------------------------------------------------------------

export const SORT_BY_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High-Low' },
  { value: 'priceAsc', label: 'Price: Low-High' },
];
export const FILTER_STATUS_OPTIONS = ['في انتظار الركاب', 'قيد التنفيذ', 'بدأت', 'انتهت', 'ملغاة'];

// ----------------------------------------------------------------------

StatusFilterSidebar.propTypes = {
  openFilter: PropTypes.bool,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
};

export default function StatusFilterSidebar({ openFilter, onOpenFilter, onCloseFilter, setLoading, setData }) {
  const {token} = useSelector(state => state.auth)

  const [selectedStatus, setSelectedStatus] = useState('');

  const handleFilter = () => {
    setLoading(true)
    onCloseFilter()
    axios.get(`${process.env.REACT_APP_API_URL}admin/filter_by_status?status=${selectedStatus}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      setLoading(false)
      setData(res.data.data)
    })
    .catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  return (
    <>
      <Button disableRipple color="inherit" endIcon={<Iconify icon="ic:round-filter-list" />} onClick={onOpenFilter}>
        Filters&nbsp;
      </Button>

      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 280, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            الفلتر
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>

            <div>
              <Typography variant="subtitle1" gutterBottom>
                الحالة
              </Typography>
              <RadioGroup value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
                {FILTER_STATUS_OPTIONS.map((item) => (
                  <FormControlLabel key={item} value={item} control={<Radio />} label={item} />
                ))}
              </RadioGroup>
            </div>

            <Box sx={{ p: 3 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="primary"
              variant="outlined"
              startIcon={<Iconify icon="ic:round-clear-all" />}
              onClick={handleFilter}
            >
              بحث
            </Button>
          </Box>

          </Stack>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            startIcon={<Iconify icon="ic:round-clear-all" />}
          >
           مسح الكل
          </Button>
        </Box>
      </Drawer>
    </>
  );
}

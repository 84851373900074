import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { headerApi } from 'src/utils/headerApi';

const AddDriver = ({ open, setOpen, setData, handleCloseMenu }) => {
  const { token } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    setErrorMessage('');
    setSuccessMessage('');
  };

  // handle car image
  const fileInputRef = useRef(null);
  const [selecteFile, setSelectFile] = useState(null);

  const handleOpenFile = () => {
    fileInputRef.current.click();
  };

  const handleSelectFile = (e) => {
    setSelectFile(e.target.files[0]);
  };


  // handle driver image 
  const driverInputRef = useRef(null)
  const [selecteFiletwo, setSelectFileTwo] = useState(null)




  const [phoneNumber, setPhoneNumber] = useState('');

  const [code, setCode] = useState('');

  const handleChangePhone = (value, info) => {
    setPhoneNumber(value);
    setCode(info.countryCallingCode);
  };




  const formik = useFormik({
    initialValues: {
      name: '',
      location: '',
      password: '',
      path_id: '',
      car_name: '',
      car_number: '',
      car_color: '',
      car_passengers_count: '',
    },
    onSubmit: (values) => {
      setLoading(true);
      setSuccessMessage("")
      setErrorMessage("")
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('country_code', `+${code}`);
      formData.append('phone', phoneNumber.replace(`+${code}`, '').replace(/\s/g, ''));
      formData.append('location', values.location);
      formData.append('password', values.password);
      formData.append('path_id', values.path_id);
      formData.append('car_name', values.car_name);
      formData.append('car_number', values.car_number);
      formData.append('car_color', values.car_color);
      formData.append('car_passengers_count', values.car_passengers_count);
      formData.append('car_image', selecteFile);
      formData.append('image', selecteFiletwo);

    setErrorMessage("")
    setSuccessMessage("")

      axios
        .post(`${process.env.REACT_APP_API_URL}admin/add_driver`, formData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setLoading(false);
          setSuccessMessage('Added Success');
          setOpen(false)

          setData((prev) => [...prev, res.data.data]);
        })
        .catch((error) => {
          console.log(error);
          if(error.response){

            setErrorMessage(error.response.data.error);
          }else{

            setErrorMessage('خطأ , أعد المحاولة رجاء');
          }
          setLoading(false);
        });
    },
  });

  const [path, setPath] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}admin/get_paths`, {
        headers: headerApi(token),
      })
      .then((res) => {
        setPath(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="alert-dialog-title">{'Add Driver'}</DialogTitle>
          <DialogContent>
            <Grid container spacing={3} sx={{ marginTop: '20px' }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="الإسم"
                  name="name"
                  required
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MuiTelInput
                  value={phoneNumber}
                  onChange={handleChangePhone}
                  onlyCountries={['SY', 'BE']}
                  required
                  label="رقم الهاتف"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="الموفع"
                  name="location"
                  required
                  value={formik.values.location}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="كلمة المرور"
                  name="password"
                  required
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="المسار"
                  name="path_id"
                  select
                  required
                  value={formik.values.city_id}
                  onChange={formik.handleChange}
                >
                  {path.map((element, index) => (
                    <MenuItem key={index} value={element.id}>
                      من {element.source_city} الى {element.destination_city}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="اسم السيارة"
                  name="car_name"
                  required
                  value={formik.values.car_name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="رقم السيارة"
                  name="car_number"
                  required
                  value={formik.values.car_number}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="لون السيارة"
                  name="car_color"
                  required
                  value={formik.values.car_color}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="عدد الركاب"
                  name="car_passengers_count"
                  required
                  type="number"
                  value={formik.values.car_passengers_count}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                <label htmlFor="file">
                  <Button variant="contained" onClick={handleOpenFile}>
                    صورة السيارة
                  </Button>
                </label>
                <input
                  id="file"
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleSelectFile}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                <label htmlFor="file">
                  <Button variant="contained" onClick={() => driverInputRef.current.click()}>
                    صورة السائق
                  </Button>
                </label>
                <input
                  id="file"
                  type="file"
                  style={{ display: 'none' }}
                  ref={driverInputRef}
                  onChange={(e) => setSelectFileTwo(e.target.files[0])}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <LoadingButton type="submit" loading={loading} autoFocus>
              Agree
            </LoadingButton>
          </DialogActions>
        </form>
        {errorMessage && (
          <Typography variant="h6" sx={{ color: 'red', textAlign: 'center', padding: '10px 20px' }}>
            {errorMessage}
          </Typography>
        )}
        {successMessage && (
          <Typography variant="h6" sx={{ color: 'green', textAlign: 'center', padding: '10px 20px' }}>
            {successMessage}
          </Typography>
        )}
      </Dialog>
    </>
  );
};

export default AddDriver;

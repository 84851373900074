import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const AddCity = ({
  open,
  setOpen,
  setData,
  setShowMap,
  markerPosition,
  setMarkerPosition,
  name,
  setName,
  nameEn,
  setNameEn,
  description,
  setDescription,
}) => {
  const { token } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');


  const handleClose = () => {
    setOpen(false);
    setErrorMessage('');
    setSuccessMessage('');
    setName('');
    setNameEn('');
    setDescription('');
    setMarkerPosition(null)
  };

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault();

    const formData = new FormData()
    formData.append("name_ar", name)
    formData.append("name_en", nameEn)
    formData.append("description", description)
    formData.append("lat", markerPosition.lat)
    formData.append("lng", markerPosition.lng)


    axios.post(`${process.env.REACT_APP_API_URL}admin/create_city`, formData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      setData(prev => [...prev, res.data.data])
      handleClose()
      setLoading(false)
    })
    .catch(error => {
      console.log(error)
      setLoading(false)
      if(error.response){

        setErrorMessage(error.response.data.error)
      }else{
        setErrorMessage("Error, please try again")
      }
    })
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="alert-dialog-title">{'Add City'}</DialogTitle>
          <DialogContent>
            <Grid container spacing={3} sx={{ marginTop: '20px' }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Name Ar"
                  name="name_ar"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Name En"
                  name="name_en"
                  required
                  value={nameEn}
                  onChange={(e) => setNameEn(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  required
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  disabled={markerPosition === null ? false : true}
                  onClick={() => setShowMap(true)}
                >
                  Get Location
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <LoadingButton type="submit" loading={loading} autoFocus>
              Agree
            </LoadingButton>
          </DialogActions>
        </form>
        {errorMessage && (
          <Typography variant="h6" sx={{ color: 'red', textAlign: 'center', padding: '10px 20px' }}>
            {errorMessage}
          </Typography>
        )}
        {successMessage && (
          <Typography variant="h6" sx={{ color: 'green', textAlign: 'center', padding: '10px 20px' }}>
            {successMessage}
          </Typography>
        )}
      </Dialog>
    </>
  );
};

export default AddCity;

import { Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import React from 'react'

const CarInfo = ({data, open, handleClose}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Car Info"}
        </DialogTitle>
        <DialogContent>
            <img src={`${process.env.REACT_APP_API_URL_IMAGE}${data.image}`} alt={data.name} style={{width: "500px", height: "300px", objectFit: "cover"}} />
          <DialogContentText id="alert-dialog-description" sx={{mt: 2}}>
            <Typography variant='h6' gutterBottom>اسم السيارة: {data.name}</Typography>
            <Typography variant='h6' gutterBottom>رقم السيارة : {data.number}</Typography>
            <Typography variant='h6' gutterBottom>لون السيارة: {data.color}</Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CarInfo
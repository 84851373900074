  import { LoadingButton } from '@mui/lab';
  import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, TextField, Typography } from '@mui/material';
  import axios from 'axios';
  import { useFormik } from 'formik';
  import React, { useEffect, useState } from 'react';
  import { useSelector } from 'react-redux';

  const UpdateCity = ({
    open,
    setOpen,
    setData,
    element,
    handleCloseMenu,
    setShowMap,
    nameUpdate,
    setNameUpdate,
    nameEnUpdate,
    setNameEnUpdate,
    descriptionUpdate,
    setDescriptionUpdate,
    markerPosition,
    setMarkerPosition,
    isRefresh
  }) => {
    const { token } = useSelector((state) => state.auth);



    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');


    const handleClose = () => {
      setOpen(false);
      handleCloseMenu();
      setErrorMessage('');
      setSuccessMessage('');
    };


    useEffect(() => {
      if (element && isRefresh) {
        setNameUpdate(element.name)
        setNameEnUpdate(element.name_en)
        setDescriptionUpdate(element.description)
        setMarkerPosition(element.center_point)
      }
    }, [element, isRefresh]);

    const handleSubmit = (e) => {
      setLoading(true)
      e.preventDefault()

      const formData = new FormData()
      formData.append("name_ar", nameUpdate)
      formData.append("name_en", nameEnUpdate)
      formData.append("description", descriptionUpdate)
      formData.append("lat", markerPosition.lat)
      formData.append("lng", markerPosition.lng)


      axios.post(`${process.env.REACT_APP_API_URL}admin/update_city/${element.id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        setLoading(false)
        setOpen(false)
        handleCloseMenu()
             setData((prev) =>
               prev.map((city) =>
               city.id === element.id
                   ? {
                       ...city,
                       name: nameUpdate,
                       name_en: nameEnUpdate,
                       description: descriptionUpdate,
                       center_point: {
                        lat: markerPosition.lat,
                        lng: markerPosition.lng
                       }
                     }
                   : city
               ))
                         } )
      .catch(error => {
        console.log(error)
        if(error.response){
          setErrorMessage(error.response.data.error)
        }
        else{
          setErrorMessage("Error, please try again")
        }
        setLoading(false)
      })
    }

    return (
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle id="alert-dialog-title">{'Update City'}</DialogTitle>
            <DialogContent>
              <Grid container spacing={3} sx={{ marginTop: '20px' }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name_ar"
                    required
                    value={nameUpdate}
                    onChange={(e) => setNameUpdate(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Name En"
                    name="name_en"
                    required
                    value={nameEnUpdate}
                    onChange={(e) => setNameEnUpdate(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    required
                    value={descriptionUpdate}
                    onChange={(e) => setDescriptionUpdate(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button variant="contained" onClick={() => setShowMap(true)}>
                    Update Location
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Disagree</Button>
              <LoadingButton type="submit" loading={loading} autoFocus>
                Agree
              </LoadingButton>
            </DialogActions>
          </form>
          {errorMessage && (
            <Typography variant="h6" sx={{ color: 'red', textAlign: 'center', padding: '10px 20px' }}>
              {errorMessage}
            </Typography>
          )}
          {successMessage && (
            <Typography variant="h6" sx={{ color: 'green', textAlign: 'center', padding: '10px 20px' }}>
              {successMessage}
            </Typography>
          )}
        </Dialog>
      </>
    );
  };

  export default UpdateCity;

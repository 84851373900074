import { LoadingButton } from '@mui/lab';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const CreateClient = ({ open, setOpen, setData, city }) => {
  const { token } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState('');
  const handleChangePhone = (value, info) => {
    setPhoneNumber(value);
    setCode(info.countryCallingCode);
  };

  const handleClose = () => {
    setOpen(false)
    setErrorMessage("")
    setSuccessMessage("")
    formik.resetForm()
    setPhoneNumber("")
    setCode("")
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      password: '',
      location: '',
      city_id: '',
    },

    onSubmit: (values) => {
      setLoading(true)
      setErrorMessage("")
      setSuccessMessage("")
      const formData = new FormData()
       formData.append('name', values.name);
       formData.append('country_code', code);
       formData.append('phone', phoneNumber.replace(`+${code}`, '').replace(/\s/g, ''));
       formData.append('password', values.password);
       formData.append('location', values.location);
       formData.append('city_id', values.city_id);
       formData.append('image', selectedFile);    
      
      axios.post(`${process.env.REACT_APP_API_URL}admin/create_client`, formData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        setLoading(false)
        console.log(res)
        setOpen(false)
        setData((prev) => [...prev, res.data.data])();
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
        if(error.response){
          setErrorMessage(error.response.data.error)
        }else{
          setErrorMessage("خطأ , أعد المحاولة رجاء")
        }
      })

      }

  });

  const fileInputRef = useRef(null)

  const [selectedFile, setSelectedFile] = useState(null)

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="alert-dialog-title">{'Add Client'}</DialogTitle>
          <DialogContent>
            <Grid container spacing={3} sx={{ marginTop: '20px' }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="الإسم"
                  name="name"
                  required
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MuiTelInput
                  value={phoneNumber}
                  onChange={handleChangePhone}
                  onlyCountries={['SY', 'BE']}
                  required
                  label="رقم الهاتف"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="كلمة المرور"
                  name="password"
                  type="password"
                  required
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="الموقع"
                  name="location"
                  required
                  value={formik.values.location}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="المدينة"
                  select
                  name="city_id"
                  required
                  value={formik.values.city_id}
                  onChange={formik.handleChange}
                >
                  {city.map((element, index) => (
                    <MenuItem key={index} value={element.id}>
                      {element.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                <label htmlFor="file">
                  <Button variant="contained" onClick={() => fileInputRef.current.click()}>
                    صورة
                  </Button>
                </label>
                <input
                  id="file"
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <LoadingButton type="submit" loading={loading} autoFocus>
              Agree
            </LoadingButton>
          </DialogActions>
        </form>
        {errorMessage && (
          <Typography variant="h6" sx={{ color: 'red', textAlign: 'center', padding: '10px 20px' }}>
            {errorMessage}
          </Typography>
        )}
        {successMessage && (
          <Typography variant="h6" sx={{ color: 'green', textAlign: 'center', padding: '10px 20px' }}>
            {successMessage}
          </Typography>
        )}
      </Dialog>
    </>
  );
};

export default CreateClient;

import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const UpdateAdmin = ({ open, setOpen, setData, element, handleCloseMenu }) => {
  const { token } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    handleCloseMenu()
    setErrorMessage('');
    setSuccessMessage('');
  };

  // handle car image
  const fileInputRef = useRef(null);
  const [selectFile, setSelectFile] = useState(null);

  const handleOpenFile = () => {
    fileInputRef.current.click();
  };

  const handleSelectFile = (e) => {
    setSelectFile(e.target.files[0]);
  };


  const [phoneNumber, setPhoneNumber] = useState('');


  const handleChangePhone = (value, info) => {
    setPhoneNumber(`+${info.countryCallingCode}-${info.nationalNumber}`)
  };






  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: (values) => {
      setLoading(true);
      const formData = new FormData();
      formData.append('name', values.name);
      let countryCode = phoneNumber.split('-')[0];
      if (!countryCode.startsWith('+')) {
        countryCode = `+${countryCode}`;
      }
      formData.append('country_code', countryCode);
      formData.append('phone', phoneNumber.split('-')[1]);
      formData.append('image', selectFile);

    setErrorMessage("")
    setSuccessMessage("")

      axios
        .post(`${process.env.REACT_APP_API_URL}admin/update_admin/${element.id}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setLoading(false);
          setSuccessMessage('Updated Success');
          setOpen(false)
          setData((prev) =>
          prev.map((admin) =>
          admin.id === element.id
              ? {
                  ...admin,
                  name: values.name,
                  country_code: countryCode,
                  phone: phoneNumber.split('-')[1],
                }
              : admin
          )
        );
        })
        .catch((error) => {
          console.log(error);
          if(error.response){
            setErrorMessage(error.response.data.error);
          }else{

            setErrorMessage('Error, please try again');
          }
          setLoading(false);
        });
    },
  });


  useEffect(() => {
    if(element){
        formik.setValues({name: element.name || ''})
    }
    setPhoneNumber(`${element.country_code}-${element.phone}`)
    
  }, [element])



  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="alert-dialog-title">{'Update Employee'}</DialogTitle>
          <DialogContent>
            <Grid container spacing={3} sx={{ marginTop: '20px' }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  required
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MuiTelInput
                  value={phoneNumber}
                  onChange={handleChangePhone}
                  onlyCountries={['SY', 'BE']}
                  required
                  label="Phone"
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                <label htmlFor="file">
                  <Button variant="contained" onClick={handleOpenFile}>
                    Image
                  </Button>
                </label>
                <input
                  id="file"
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleSelectFile}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <LoadingButton type="submit" loading={loading} autoFocus>
              Agree
            </LoadingButton>
          </DialogActions>
        </form>
        {errorMessage && (
          <Typography variant="h6" sx={{ color: 'red', textAlign: 'center', padding: '10px 20px' }}>
            {errorMessage}
          </Typography>
        )}
        {successMessage && (
          <Typography variant="h6" sx={{ color: 'green', textAlign: 'center', padding: '10px 20px' }}>
            {successMessage}
          </Typography>
        )}
      </Dialog>
    </>
  );
};

export default UpdateAdmin;

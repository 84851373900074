import { IconButton, TableCell, TableRow } from '@mui/material'
import React from 'react'
import Iconify from 'src/components/iconify'

const PathsTableRow = ({element, handleOpenMenu}) => {
  return (
    <>
        <TableRow>
            <TableCell align='left'>{element.source_city}</TableCell>
            <TableCell align='left'>{element.destination_city}</TableCell>
            <TableCell align='left'>{element.one_ticket_price}</TableCell>
            <TableCell align='left'>{element.access_time}</TableCell>
            <TableCell align="right">
          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, element)}>
            <Iconify icon={'eva:more-vertical-fill'} />
          </IconButton>
        </TableCell>
        </TableRow>
    </>
  )
}

export default PathsTableRow
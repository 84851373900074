import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker  } from '@react-google-maps/api';
import { Box, Button, Typography } from '@mui/material';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const center = {
  lat: 33.50006,
  lng: 36.2973314,
};


const Map = ({setShowMap, markerPosition, setMarkerPosition, setIsRefresh}) => {

  //initial google map
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOLEMAP_KEY,
  });

  const [map, setMap] = useState(null);


  const onLoad = useCallback(function callback(map) {
    map.setZoom(14);
    setMap(map);
  
    const listener = map.addListener('click', (event) => {
      setMarkerPosition({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    });
  
    return () => {
      window.google.maps.event.removeListener(listener);
    };
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  //add marker to get lat and lng 


  const [errorMessage, setErrorMessage] = useState("")
  const errorRef = useRef(null)

  useEffect(() => {
    errorRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [errorMessage]);

  const handleClick = () => {

    if (markerPosition) {
      const { lat, lng } = markerPosition;
      // console.log('Latitude:', lat);
      // console.log('Longitude:', lng);
      setShowMap(false)
      setIsRefresh(false)
    }else{
      setErrorMessage("Please, select a place")
    }

  };

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={0}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
         {markerPosition && (
    <Marker position={markerPosition} />
  )}
      </GoogleMap>

      <Box sx={{textAlign: "center", mt: 5}}>
        <Button variant='contained'  onClick={handleClick}>Get Location</Button>
      </Box>
      {errorMessage && (
          <Typography ref={errorRef} variant="h6" sx={{ color: 'red', textAlign: 'center', padding: '10px 20px' }}>
            {errorMessage}
          </Typography>
        )}
    </>
  ) : (
    <></>
  );
};

export default Map;

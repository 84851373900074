import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  Popover,
  MenuItem,
  TableBody,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import SkeletonTabel from 'src/components/SkeletonTabel';
import { logoutUser } from 'src/store/authSlice';
import DriversTableRow from 'src/sections/@dashboard/drivers/DriversTableRow';
import CarInfo from 'src/sections/client/CarInfo';
import AddDriver from 'src/sections/driver/AddDriver';
import UpdateDriver from 'src/sections/driver/UpdateDriver';
import { onMessage } from 'firebase/messaging';
import { generateToken, messaging } from 'src/notifications/firebase';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'الاسم', alignRight: false },
  { id: 'phone', label: 'الهاتف', alignRight: false },
  { id: 'location', label: 'الموقع', alignRight: false },
  { id: 'city', label: 'المدينة', alignRight: false },
  { id: 'status', label: 'الحالة', alignRight: false },
  { id: 'wallet', label: 'المحفظة', alignRight: false },
  { id: 'is_online', label: 'متصل', alignRight: false },
  { id: 'started_online_at', label: 'بدأ الاتصال عبر الانترنت' },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function Drivers() {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event, element) => {
    event.stopPropagation();
    setSelectedDriverCar(element.car);
    setAnchorEl(event.currentTarget);
    setSelectedDriver(element);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const { token } = useSelector((state) => state.auth);

  const [drivers, setDrivers] = useState([]);

  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    setLoadingData(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}admin/get_drivers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDrivers(res.data.data);
        setLoadingData(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(logoutUser());
        }
        console.log(error);
        setLoadingData(false);
      });
  }, [token]);

  const [OpenAdd, setOpenAdd] = useState(false);

  //handle delete admin

  const [selectedDriver, setSelectedDriver] = useState({});

  // handle update
  const [openUpdate, setOpenUpdate] = useState(false);

  const [selectedDriverCar, setSelectedDriverCar] = useState({});

  const handleUpdate = () => {
    setOpenUpdate(true);
  };

  // handle car info

  const [openCarInfo, setOpenCarInfo] = useState(false);

  const handleCloseCar = () => {
    setOpenCarInfo(false);
    setAnchorEl(null);
  };

  const [payloadNotification, setPayloadNotification] = useState({});
  useEffect(() => {
    generateToken();

    onMessage(messaging, (payload) => {
      // console.log(payload.data.key);
      if (payload.data.key === 'driver') {
        setPayloadNotification(JSON.parse(payload.data.driver));
        // console.log(payload.data.driver);
        console.log(JSON.parse(payload.data.driver));
      }
    });
  }, [payloadNotification]);
  // console.log(drivers);
  return (
    <>
      <Helmet>
        <title> السائقين </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            السائقين
          </Typography>
          <Button onClick={() => setOpenAdd(true)} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            سائق جديد
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {/* {element?.id === payloadNotification?.id ? payloadNotification.status : 'asdas'} */}
                  {loadingData ? (
                    <SkeletonTabel number={7} />
                  ) : (
                    drivers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((element, index) => {
                      return (
                        <DriversTableRow
                          element={element}
                          key={index}
                          handleOpenMenu={handleOpenMenu}
                          status={element?.id === payloadNotification?.id ? payloadNotification?.status : ''}
                        />
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => setOpenCarInfo(true)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          معلومات السيارة
        </MenuItem>
        <MenuItem onClick={handleUpdate}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          تحديث السائق
        </MenuItem>
      </Popover>
      <CarInfo open={openCarInfo} handleClose={handleCloseCar} data={selectedDriverCar} />
      <AddDriver open={OpenAdd} setOpen={setOpenAdd} setData={setDrivers} handleCloseMenu={handleCloseMenu} />
      <UpdateDriver
        element={selectedDriver}
        open={openUpdate}
        setOpen={setOpenUpdate}
        setData={setDrivers}
        handleCloseMenu={handleCloseMenu}
      />
    </>
  );
}

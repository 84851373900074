import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const AddPath = ({ open, setOpen, setData, setAnchorEl, element }) => {
  const { token } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null)
    // formik.resetForm();
    setErrorMessage('');
    setSuccessMessage('');
  };

  const formik = useFormik({
    initialValues: {
      one_ticket_price: '',
      access_time: '',
    },
    onSubmit: (values) => {
      setLoading(true);
      const formData = new FormData();
      formData.append('one_ticket_price', values.one_ticket_price);
      formData.append('access_time', values.access_time.replace(/\D/g, ''))

      setErrorMessage('');
      setSuccessMessage('');

      axios
        .post(`${process.env.REACT_APP_API_URL}admin/update_path/${element.id}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setLoading(false);
          setSuccessMessage('Added Success');
        setData((prev) =>
        prev.map((path) =>
        path.id === element.id
            ? {
                ...path,
                one_ticket_price: values.one_ticket_price,
                access_time: values.access_time,
              }
            : path
        )
      );
          handleClose();
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            setErrorMessage(error.response.data.error);
          } else {
            setErrorMessage('Error, please try again');
          }
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if(element){
        formik.setValues({
            one_ticket_price: element.one_ticket_price || '',
            access_time: element.access_time || ''
        })
    }
  }, [element])

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="alert-dialog-title">{'Update Path'}</DialogTitle>
          <DialogContent>
            <Grid container spacing={3} sx={{ marginTop: '20px' }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="One Ticket Price"
                  name="one_ticket_price"
                  required
                  value={formik.values.one_ticket_price}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Access Time"
                  name="access_time"
                  required
                  value={formik.values.access_time}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <LoadingButton type="submit" loading={loading} autoFocus>
              Agree
            </LoadingButton>
          </DialogActions>
        </form>
        {errorMessage && (
          <Typography variant="h6" sx={{ color: 'red', textAlign: 'center', padding: '10px 20px' }}>
            {errorMessage}
          </Typography>
        )}
        {successMessage && (
          <Typography variant="h6" sx={{ color: 'green', textAlign: 'center', padding: '10px 20px' }}>
            {successMessage}
          </Typography>
        )}
      </Dialog>
    </>
  );
};

export default AddPath;

import SvgColor from '../../../components/svg-color';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'لوحة التحكم',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'العملاء',
    path: '/dashboard/clients',
    icon: icon('ic_analytics'),
  },
  {
    title: 'السائقين',
    path: '/dashboard/drivers',
    icon: icon('ic_user'),
  },
  {
    title: 'الموظفين',
    path: '/dashboard/employee',
    icon: icon('ic_user'),
  },
  {
    title: 'الرحلات',
    path: '/dashboard/trips',
    icon: icon('ic_user'),
  },
  {
    title: 'المسارات',
    path: '/dashboard/paths',
    icon: icon('ic_disabled'),
  },
  {
    title: 'المدينة',
    path: '/dashboard/city',
    icon: icon('ic_disabled'),
  },
  {
    title: 'تسجيل الدخول',
    path: '/login',
    icon: icon('ic_lock'),
  },
  {
    title: 'غير موجود',
    path: '/404',
    icon: icon('ic_disabled'),
  },
];

export default navConfig;

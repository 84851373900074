import {
  Button,
  Card,
  Container,
  MenuItem,
  Popover,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Scrollbar from 'src/components/scrollbar';
import { UserListHead } from '../user';
import SkeletonTable from 'src/components/SkeletonTabel';
import CityTablerow from './CityTablerow';
import USERLIST from '../../../_mock/user';
import AddCity from './AddCity';
import Iconify from 'src/components/iconify';
import UpdateCity from '../paths/UpdateCity';

const TABLE_HEAD = [
  { id: 'name', label: 'الاسم', alignRight: false },
  { id: 'name_en', label: 'الاسم بالإنجليزية', alignRight: false },
  { id: 'description', label: 'الوصف', alignRight: false },
  { id: '' },
];

const CityComp = ({
  city,
  setCity,
  loading,
  setShowMap,
  openAdd,
  setOpenAdd,
  markerPosition,
  setMarkerPosition,
  name,
  setName,
  nameEn,
  setNameEn,
  description,
  setDescription,
  openUpdate,
  setOpenUpdate,
  nameUpdate,
  setNameUpdate,
  nameEnUpdate,
  setNameEnUpdate,
  descriptionUpdate,
  setDescriptionUpdate,
  selectedElement,
  setSelectedElement,
  isRefresh,
}) => {
  // handle Table pagination
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  //handle open popover
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu = (e, element) => {
    setAnchorEl(e.currentTarget);
    setSelectedElement(element);
  };

  //handle update

  return (
    <>
      <Container sx={{ my: 5 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            المدن
          </Typography>
          <Button variant="contained" onClick={() => setOpenAdd(true)}>
            إضافة مدينة
          </Button>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {loading ? (
                    <SkeletonTable number={2} />
                  ) : (
                    city
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((element, index) => (
                        <CityTablerow key={index} element={element} handleOpenMenu={handleOpenMenu} />
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => setOpenUpdate(true)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          تحديث المدينة
        </MenuItem>
      </Popover>

      <AddCity
        open={openAdd}
        setOpen={setOpenAdd}
        setData={setCity}
        setShowMap={setShowMap}
        markerPosition={markerPosition}
        setMarkerPosition={setMarkerPosition}
        name={name}
        setName={setName}
        nameEn={nameEn}
        setNameEn={setNameEn}
        description={description}
        setDescription={setDescription}
      />
      <UpdateCity
        open={openUpdate}
        setOpen={setOpenUpdate}
        element={selectedElement}
        setData={setCity}
        handleCloseMenu={handleCloseMenu}
        setShowMap={setShowMap}
        nameUpdate={nameUpdate}
        setNameUpdate={setNameUpdate}
        nameEnUpdate={nameEnUpdate}
        setNameEnUpdate={setNameEnUpdate}
        descriptionUpdate={descriptionUpdate}
        setDescriptionUpdate={setDescriptionUpdate}
        markerPosition={markerPosition}
        setMarkerPosition={setMarkerPosition}
        isRefresh={isRefresh}
      />
    </>
  );
};

export default CityComp;

import { Avatar, Stack, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';

const DriverCarTable = ({element}) => {
  return (
    <>
      <TableRow>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar alt={element.name} src={`${process.env.REACT_APP_API_URL_IMAGE}${element.image}`} />
          <Typography variant="subtitle2" noWrap>
            {element.name}
          </Typography>
        </Stack>
        <TableCell align="left">{element.number}</TableCell>
        <TableCell align="left">{element.color}</TableCell>
        <TableCell align="left">{element.passengers_count}</TableCell>
      </TableRow>
    </>
  );
};

export default DriverCarTable;

import { IconButton, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Iconify from 'src/components/iconify';

const TripTableRow = ({ element, handleOpenMenu, status }) => {
  const navigate = useNavigate();
  return (
    <>
      <TableRow sx={{ cursor: 'pointer' }} onClick={() => navigate(`/dashboard/trips/details/${element.id}`)}>
        <TableCell align="left">{element.started_at}</TableCell>
        <TableCell align="left">{element.finished_at}</TableCell>
        <TableCell align="left">{element.seats_count}</TableCell>
        <TableCell align="left">{status === '' ? element.status : status}</TableCell>
        <TableCell align="left">{element.evaluation}</TableCell>
        <TableCell align="left">{element.price}</TableCell>
        <TableCell align="left">{element.company_rate}</TableCell>
        <TableCell align="right">
          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, element)}>
            <Iconify icon={'eva:more-vertical-fill'} />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TripTableRow;

import {
  Card,
  CircularProgress,
  Container,
  MenuItem,
  Popover,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import SkeletonTable from 'src/components/SkeletonTabel';
import Scrollbar from 'src/components/scrollbar';
import TripTableRow from 'src/sections/@dashboard/trips/TripTableRow';
import { UserListHead } from 'src/sections/@dashboard/user';
import USERLIST from '../_mock/user';
import Iconify from 'src/components/iconify';
import DriverInfo from 'src/sections/client/DriverInfo';
import StatusFilterSidebar from 'src/sections/@dashboard/products/ProductFilterSidebar';
import { onMessage } from 'firebase/messaging';
import { generateToken, messaging } from 'src/notifications/firebase';
const TABLE_HEAD = [
  { id: 'started_at', label: 'بدء في', alignRight: false },
  { id: 'finished_at', label: 'انتهى في', alignRight: false },
  { id: 'seats_count', label: 'عدد المقاعد', alignRight: false },
  { id: 'status', label: 'الحالة', alignRight: false },
  { id: 'evaluation', label: 'التقييم', alignRight: false },
  { id: 'price', label: 'السعر', alignRight: false },
  { id: 'company_rate', label: 'تقييم الشركة', alignRight: false },
  { id: '' },
];


const Trips = () => {
  const { token } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [trips, setTrips] = useState([]);

  const [tripStatus, setTripStatus] = useState([
    { status: 'waiting_passengers', loading: false },
    { status: 'in_progress', loading: false },
    { status: 'started', loading: false },
    { status: 'finished', loading: false },
    { status: 'Cancelled', loading: false },
  ]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}admin/get_all_trips`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setTrips(res.data.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [token]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  //handle popover
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [selectedtrip, setSelectedTrip] = useState({});
  const [selectedId, setSelectedId] = useState('');
  const handleOpenMenu = (e, element) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelectedTrip(element.driver);
    setSelectedId(element.id);
  };

  //   handle driver popover

  const [openDriverinfo, setOpenDriverinfo] = useState(false);

  const handleCloseDriver = () => {
    setOpenDriverinfo(false);
    setAnchorEl(null);
  };

  //   handle update trip
  const [anchorEdit, setAnchorEdit] = useState(null);

  const openEdit = Boolean(anchorEdit);

  const id = openEdit ? 'simple-popover' : undefined;

  const handleCloseUpdate = () => {
    setAnchorEdit(null);
    setAnchorEl(null);
  };

  const handleUpdateStatus = (element) => {
    setTripStatus((prevStatus) =>
      prevStatus.map((el) => ({
        ...el,
        loading: el.status === element.status,
      }))
    );
    axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/update_trip_status/${selectedId}`,
        { status: element.status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTripStatus((prevStatus) =>
          prevStatus.map((el) => ({
            ...el,
            loading: false,
          }))
        );
        handleCloseUpdate();

        setTrips((prevTrips) =>
          prevTrips.map((trip) => {
            if (trip.id === selectedId) {
              return {
                ...trip,
                status: element.status,
              };
            }
            return trip;
          })
        );
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setTripStatus((prevStatus) =>
          prevStatus.map((el) => ({
            ...el,
            loading: false,
          }))
        );
      });
  };

  // handle filter
  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  const [payloadNotification, setPayloadNotification] = useState({});

  useEffect(() => {
    generateToken();

    onMessage(messaging, (payload) => {
      // console.log(payload.data.key);
      if (payload.data.key === 'trip') {
        setPayloadNotification(JSON.parse(payload.data.trip));
        // console.log(payload.data.driver);
      }
    });
  }, [payloadNotification]);
  // console.log(trips);
  return (
    <>
      <Helmet>
        <title> User | Minimal UI </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            الرحلات
          </Typography>
        </Stack>
        <StatusFilterSidebar
          openFilter={openFilter}
          onOpenFilter={handleOpenFilter}
          onCloseFilter={handleCloseFilter}
          setLoading={setLoading}
          setData={setTrips}
        />
        <Card>
          <Scrollbar>
            <TableContainer>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {loading ? (
                    <SkeletonTable number={5} />
                  ) : (
                    trips
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((element, index) => (
                        <TripTableRow
                          element={element}
                          key={index}
                          handleOpenMenu={handleOpenMenu}
                          status={element?.id === payloadNotification?.id ? payloadNotification?.status : ''}
                        />
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => setOpenDriverinfo(true)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          معلومات الرحلة
        </MenuItem>
        <MenuItem onClick={(e) => setAnchorEdit(e.currentTarget)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          تحديث الرحلة
        </MenuItem>
      </Popover>

      <Popover
        id={id}
        open={openEdit}
        anchorEl={anchorEdit}
        onClose={handleCloseUpdate}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {tripStatus.map((element, index) => (
          <MenuItem key={index} onClick={() => handleUpdateStatus(element)}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            {element.loading ? <CircularProgress size={20} /> : element.status}
          </MenuItem>
        ))}
      </Popover>

      <DriverInfo data={selectedtrip} open={openDriverinfo} handleClose={handleCloseDriver} />
    </>
  );
};

export default Trips;

import { TableCell, TableRow } from '@mui/material';
import React from 'react';

const TripDetailsTableRow = ({ element }) => {
  return (
    <>
      <TableRow>
        <TableCell align="left">{element.client_name}</TableCell>
        <TableCell align="left">{element.client_phone}</TableCell>
        <TableCell align="left">{element.seats_count}</TableCell>
        <TableCell align="left">{element.client_point}</TableCell>
        <TableCell align="left">{element.price}</TableCell>
        <TableCell align="left">{element.evaluation}</TableCell>
        <TableCell align="left">{element.is_cancelled}</TableCell>
      </TableRow>
    </>
  );
};

export default TripDetailsTableRow;

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

const TripMap = ({ open, handleClose, element }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    let lat = '';
    let lng = '';
  
    if (element && element.orders && element.orders.length > 0) {
      const clientPoint = element.orders[0]?.client_point?.split(",");
      if (clientPoint && clientPoint.length === 2) {
        lat = clientPoint[0];
        lng = clientPoint[1];
      }
    }
  
    const url = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.521260322283!2d106.8195613507864!3d-6.194741395493371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f5390917b759%3A0x6b45e67356080477!2sPT%20Kulkul%20Teknologi%20Internasional!5e0!3m2!1sen!2sid!4v1601138221085!5m2!1sen!2sid&q=${lat},${lng}`;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`${searchParams.get('name')} order`}</DialogTitle>
        <DialogContent sx={{width: "500px", height: "500px"}}>
          <iframe
            title="map"
            src={url}
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TripMap;

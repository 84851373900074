import { Avatar, Stack, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';

const PathDriverTableRow = ({element}) => {
  return (
    <TableRow>
      <TableCell align="left">
      <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar alt={element.name} src={`${process.env.REACT_APP_API_URL_IMAGE}${element.image}`} />
          <Typography variant="subtitle2" noWrap>
            {element.name}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell align="left">{element.phone}</TableCell>
      <TableCell align="left">{element.location}</TableCell>
    </TableRow>
  );
};

export default PathDriverTableRow;

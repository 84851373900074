import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import CityComp from 'src/sections/@dashboard/paths/CityComp';
import PathsComp from 'src/sections/@dashboard/paths/PathsComp';

const Paths = () => {
  const { token } = useSelector((state) => state.auth);

  const [cityLoading, setCityLoading] = useState(true)
  const [city, setCity] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}admin/get_cities`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCityLoading(false)
        setCity(res.data.data);
      })
      .catch((error) => {
        console.log(error);
        setCityLoading(false)
      });
  }, [token]);

  return (
    <>
      <Helmet>
        <title> User | Minimal UI </title>
      </Helmet>
      <PathsComp city={city} />
    </>
  );
};

export default Paths;
